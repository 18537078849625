<template>
  <div class="user-tab-overview">
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="roleFilter"
            placeholder="Select Role"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col> -->

        <!-- plan filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="planFilter"
            placeholder="Select Plan"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col> -->

        <!-- status filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Select Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col> -->
      </v-row>

      <!-- <v-divider class="mt-4"></v-divider> -->

      <!-- actions -->
      <!-- <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-row
          align="center"
          align-content="center"
          justify="space-between"
        >
          <v-col
            cols="12"
            md="8"
          >
            <table-search
              v-model="options.q"
              dense
              outlined
              hide-details
              full-width
            />
          </v-col>
        </v-row>
      </v-card-text> -->

      <!-- table -->
      <vuex-table
        :headers="tableColumns"
        :items="items"
        :options="options"
        :search.sync="search"
        :meta="meta"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 50, 100]
        }"
        @update:options="o => options = o"
      >
        <!-- name -->
        <template #[`item.displayName`]="{item}">
          <router-link
            v-if="item.displayName"
            :to="{ name : 'item-view', params : { id : item.id } }"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ item.displayName }}
          </router-link>
          <span v-else>-</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name:'item-view', params:{ id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Item</span>
          </v-tooltip>
        </template>
      </vuex-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf, mdiEyeOutline } from '@mdi/js'
import { onMounted, onUnmounted } from '@vue/composition-api'
import useItemList from './useItemList'

export default {
  props: {
    itemCategoryId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      itemListTable,
      tableColumns,

      totalItemListTable,
      loading,
      options,

      // parentFilter,
      // itemTotalLocal,

      items,
      meta,
      search,

      init,
      destroy,
    } = useItemList(props.itemCategoryId)

    onMounted(() => {
      init()
    })

    onUnmounted(() => {
      destroy()
    })

    return {
      icons: {
        mdiFilePdf,
        mdiEyeOutline,
        mdiDotsVertical,
      },

      itemListTable,
      tableColumns,

      totalItemListTable,
      loading,
      options,

      // parentFilter,
      // itemTotalLocal,

      items,
      meta,
      search,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>

import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"user-bio-panel"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pt-10"},[_c(VCardTitle,{staticClass:"justify-center flex-column"},[_c(VAvatar,{staticClass:"mb-4",class:_vm.data.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":_vm.data.avatar ? '' : 'primary',"size":"120","rounded":""}},[(_vm.data.avatar)?_c(VImg,{attrs:{"src":require(("@/assets/images/avatars/" + (_vm.data.avatar)))}}):_c('span',{staticClass:"font-weight-semibold text-5xl"},[_vm._v(_vm._s(_vm.avatarText(_vm.data.code)))])],1),_c('span',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm._f("textEllipsis")(_vm.data.code,90)))])],1),_c(VCardText,{staticClass:"d-flex justify-center flex-wrap mt-2 pe-sm-0"},[_c('div',{staticClass:"d-flex align-center me-8 mb-4"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text me-3",attrs:{"size":"40","rounded":"","color":"primary"}},[_c(VIcon,{attrs:{"color":"primary","size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDetails)+" ")])],1),_c('div',[_c('h3',{staticClass:"text-xl font-weight-medium mb-n1"},[_vm._v(" "+_vm._s(_vm.kFormatter(_vm.data.categoriesCount))+" ")]),_c('span',[_vm._v("Inner Categories")])])],1),_c('div',{staticClass:"d-flex align-center mb-4 me-4"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text me-3",attrs:{"size":"40","rounded":"","color":"primary"}},[_c(VIcon,{attrs:{"color":"primary","size":"22"}},[_vm._v(" "+_vm._s(_vm.data.parentId ? _vm.icons.mdiCheck : _vm.icons.mdiClose)+" ")])],1),_c('div',[_c('span',[_vm._v("Has Parent Category")])])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import itemCategoryStoreModule from '@/store/itemCategories/itemCategory'
import { computed, getCurrentInstance, ref } from '@vue/composition-api'

export default function useItemCategoryView(itemCategoryId) {
  const vm = getCurrentInstance().proxy
  const id = ref(itemCategoryId)

  const MODULE_CONTAINER = 'itemCategories'
  const STORE_MODULE = computed(() => `${MODULE_CONTAINER}/${id.value}`)

  const itemCategory = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/data`],
  })
  const loading = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/loading`],
  })

  // fetch data
  const fetchData = () => vm.$store.dispatch(`${STORE_MODULE.value}/fetchData`)

  // const fetchCategories = () => vm.$store.dispatch(`${STORE_MODULE.value}/fetchCategories`)

  // onInit
  const init = () => {
    if (!vm.$store.hasModule([MODULE_CONTAINER, id.value])) {
      vm.$store.registerModule([MODULE_CONTAINER, id.value], itemCategoryStoreModule(itemCategoryId))
    }

    vm.$store.dispatch(`${STORE_MODULE.value}/init`, id.value)

    fetchData()

    // fetchCategories()
  }

  // onDestroy
  const destroy = () => {
    // if (vm.$store.hasModule([MODULE_CONTAINER, id.value])) {
    //   vm.$store.unregisterModule([MODULE_CONTAINER, id.value])
    // }
  }

  // watch([], () => {
  // loading.value = true
  // fetchData()
  // })

  return {
    loading,
    itemCategory,

    init,
    destroy,
    fetchData,
  }
}

import itemCategoryStoreModule from '@/store/itemCategories/itemCategory'
// eslint-disable-next-line no-unused-vars, object-curly-newline
import { computed, getCurrentInstance, ref, watch } from '@vue/composition-api'

export default function useItemCategoryList(itemCategoryId) {
  const vm = getCurrentInstance().proxy

  const id = ref(itemCategoryId)
  const itemCategoryListTable = ref([])

  const MODULE_CONTAINER = 'itemCategories'
  const STORE_MODULE = `${MODULE_CONTAINER}/${id.value}/itemCategories`

  const itemCategories = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/items`],
  })
  const loading = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/loading`],
  })
  const totalItemCategoryListTable = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/meta`].total,
  })
  const meta = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/meta`],
  })
  const options = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/options`],
    set: val => vm.$store.dispatch(`${STORE_MODULE}/setOptions`, { ...options.value, ...val }),
  })

  const search = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/search`],
    set: val => vm.$store.dispatch(`${STORE_MODULE}/setSearch`, val),
  })

  const tableColumns = [
    { text: 'CODE', value: 'code' },
    { text: 'NAME', value: 'displayName' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const fetchItems = () => vm.$store.dispatch(`${STORE_MODULE}/fetchItems`)
  const setOptions = val => vm.$store.dispatch(`${STORE_MODULE}/setOptions`, val)

  // onInit
  const init = () => {
    if (!vm.$store.hasModule([MODULE_CONTAINER, id.value])) {
      vm.$store.registerModule([MODULE_CONTAINER, id.value], itemCategoryStoreModule(itemCategoryId))
    }

    vm.$store.dispatch(`${STORE_MODULE}/init`, id.value)

    fetchItems()
  }

  // onDestroy
  const destroy = () => {
    // TODO: SHOULD DESTROY MODULES?
    // if (vm.$store.hasModule([MODULE_CONTAINER, id.value])) {
    //   vm.$store.unregisterModule([MODULE_CONTAINER, id.value])
    // }
  }

  return {
    itemCategoryListTable,
    tableColumns,

    totalItemCategoryListTable,
    loading,
    options,

    itemCategories,
    meta,
    search,

    init,
    destroy,
    fetchItems,
    setOptions,
  }
}

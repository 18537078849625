<template>
  <div class="user-tab-overview">
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="roleFilter"
            placeholder="Select Role"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col> -->

        <!-- plan filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="planFilter"
            placeholder="Select Plan"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col> -->

        <!-- status filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Select Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col> -->
      </v-row>

      <!-- <v-divider class="mt-4"></v-divider> -->

      <!-- actions -->
      <!-- <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-row
          align="center"
          align-content="center"
          justify="space-between"
        >
          <v-col
            cols="12"
            md="8"
          >
            <table-search
              v-model="options.q"
              dense
              outlined
              hide-details
              full-width
            />
          </v-col>
        </v-row>
      </v-card-text> -->

      <!-- table -->
      <vuex-table
        :headers="tableColumns"
        :items="itemCategories"
        :options="options"
        :search.sync="search"
        :meta="meta"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 50, 100]
        }"
        @update:options="o => options = o"
      >
        <!-- name -->
        <template #[`item.displayName`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'item-category-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.displayName }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- parent code -->
        <template #[`item.parentCode`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'item-category-view', params : { id : item.parentId } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.parentCode }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- parent name -->
        <template #[`item.parentDisplayName`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'item-category-view', params : { id : item.parentId } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.parentDisplayName }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name: 'item-category-view', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Category</span>
          </v-tooltip>
        </template>
      </vuex-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf, mdiEyeOutline } from '@mdi/js'
import { onMounted, onUnmounted } from '@vue/composition-api'
import useItemCategoryList from './useItemCategoryList'

export default {
  props: {
    itemCategoryId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      itemCategoryListTable,
      tableColumns,

      totalItemCategoryListTable,
      loading,
      options,
      search,

      // parentFilter,
      // itemCategoryTotalLocal,

      itemCategories,
      meta,

      init,
      destroy,
    } = useItemCategoryList(props.itemCategoryId)

    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      destroy()
    })

    const tableColumnHeaders = [
      {
        text: 'PROJECT',
        value: 'project',
        sortable: false,
      },
      { text: 'TOTAL TASK', value: 'totalTask', sortable: false },
      { text: 'PROGRESS', value: 'progress', sortable: false },
      { text: 'HOURS', value: 'hours', sortable: false },
    ]
    const projectList = [
      {
        logo: 'react.png',
        name: 'BGC eCommerce App',
        project: 'React Project',
        totalTask: '122/240',
        progress: 78,
        hours: '18:42',
      },
      {
        logo: 'figma.png',
        name: 'Falcon Logo Design',
        project: 'Figma Project',
        totalTask: '9/56',
        progress: 18,
        hours: '20:42',
      },
      {
        logo: 'vue.png',
        name: 'Dashboard Design',
        project: 'Vuejs Project',
        totalTask: '290/320',
        progress: 62,
        hours: '120:87',
      },
      {
        logo: 'xamarin.png',
        name: 'Foodista mobile app',
        project: 'Xamarin Project',
        totalTask: '290/320',
        progress: 8,
        hours: '120:87',
      },
      {
        logo: 'python.png',
        name: 'Dojo Email App',
        project: 'Python Project',
        totalTask: '120/186',
        progress: 49,
        hours: '230:10',
      },
      {
        logo: 'sketch.png',
        name: 'Blockchain Website',
        project: 'Sketch Project',
        totalTask: '99/109',
        progress: 92,
        hours: '342:41',
      },
      {
        logo: 'html.png',
        name: 'Hoffman Website',
        project: 'HTML Project',
        totalTask: '98/110',
        progress: 88,
        hours: '12:45',
      },
    ]

    const resolveUserProgressVariant = progrss => {
      if (progrss <= 25) return 'error'
      if (progrss > 25 && progrss <= 50) return 'warning'
      if (progrss > 50 && progrss <= 75) return 'primary'
      if (progrss > 75 && progrss <= 100) return 'success'

      return 'secondary'
    }

    return {
      tableColumnHeaders,
      projectList,
      resolveUserProgressVariant,
      icons: {
        mdiFilePdf,
        mdiEyeOutline,
        mdiDotsVertical,
      },

      itemCategoryListTable,
      tableColumns,

      totalItemCategoryListTable,
      loading,
      options,
      search,

      // parentFilter,
      // itemCategoryTotalLocal,

      itemCategories,
      meta,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>

import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"item-category-view"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"5","lg":"4"}},[(_vm.itemCategory)?_c('item-category-bio-panel',{attrs:{"data":_vm.itemCategory}}):_vm._e()],1),_c(VCol,{attrs:{"cols":"12","md":"7","lg":"8"}},[_c(VTabs,{staticClass:"transparent-tabs",attrs:{"show-arrows":""},model:{value:(_vm.itemCategoryTab),callback:function ($$v) {_vm.itemCategoryTab=$$v},expression:"itemCategoryTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.icon,attrs:{"append":tab.append,"to":tab.to}},[_c(VIcon,{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c(VTabsItems,{staticClass:"mt-5 pa-1",attrs:{"id":"transparent-tabs-content"},model:{value:(_vm.itemCategoryTab),callback:function ($$v) {_vm.itemCategoryTab=$$v},expression:"itemCategoryTab"}},[(_vm.itemCategory)?_c(VTabItem,{attrs:{"value":"overvew"}},[_c('item-category-tab-overview',{attrs:{"data":_vm.itemCategory}})],1):_vm._e(),(_vm.itemCategory)?_c(VTabItem,{attrs:{"value":"categories"}},[_c('item-category-tab-categories',{attrs:{"item-category-id":_vm.itemCategory.id}})],1):_vm._e(),(_vm.itemCategory)?_c(VTabItem,{attrs:{"value":"items"}},[_c('item-category-tab-items',{attrs:{"item-category-id":_vm.itemCategory.id}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
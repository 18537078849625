<template>
  <div id="item-category-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <item-category-bio-panel
          v-if="itemCategory"
          :data="itemCategory"
        ></item-category-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="itemCategoryTab"
          show-arrows
          class="transparent-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :append="tab.append"
            :to="tab.to"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="transparent-tabs-content"
          v-model="itemCategoryTab"
          class="mt-5 pa-1"
        >
          <v-tab-item
            v-if="itemCategory"
            value="overvew"
          >
            <item-category-tab-overview :data="itemCategory"></item-category-tab-overview>
          </v-tab-item>
          <v-tab-item
            v-if="itemCategory"
            value="categories"
          >
            <item-category-tab-categories :item-category-id="itemCategory.id"></item-category-tab-categories>
          </v-tab-item>
          <v-tab-item
            v-if="itemCategory"
            value="items"
          >
            <item-category-tab-items :item-category-id="itemCategory.id"></item-category-tab-items>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- <v-dialog
      v-model="isPlanUpgradeDialogVisible"
      max-width="650"
    >
      <v-card class="py-8">
        <v-card-title class="justify-center text-h5 px-5">
          Upgrade Plan
        </v-card-title>
        <v-card-text class="text-center mt-n2 px-5">
          Choose the best plan for user.
        </v-card-text>
        <v-card-text class="d-flex align-center flex-wrap flex-sm-nowrap mt-5 px-15">
          <v-select
            v-model="selectedPlan"
            label="Choose Plan"
            :items="plansList"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details
            class="me-3"
          ></v-select>
          <v-btn
            color="primary"
            class="mt-3 mt-sm-0"
          >
            Upgrade
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-15 pt-8">
          <p class="font-weight-medium text--primary mb-2">
            User current plan is {{ itemCategory.currentPlan }} plan
          </p>
          <div class="d-flex justify-space-between flex-wrap">
            <div class="user-pricing me-3">
              <sup class="primary--text">$</sup>
              <span class="text-5xl font-weight-semibold primary--text">{{ resolveCurrentPlanValue(itemCategory.currentPlan) }}</span>
              <sub class="text-base font-weight-light">/ month</sub>
            </div>
            <v-btn
              color="error"
              outlined
              class="mt-3"
            >
              Cancel Subscription
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { onUnmounted, ref, watch, onUpdated } from '@vue/composition-api'

// import router from '@/router'
import { useRouter } from '@core/utils'

// import store from '@/store'

// eslint-disable-next-line object-curly-newline, no-unused-vars
import { mdiAccountOutline, mdiClipboardListOutline, mdiFormatListBulleted } from '@mdi/js'
import ItemCategoryBioPanel from './item-category-bio-panel/ItemCategoryBioPanel.vue'
import ItemCategoryTabItems from './item-category-tab-items/ItemCategoryTabItems.vue'
import ItemCategoryTabOverview from './item-category-tab-overview/ItemCategoryTabOverview.vue'
import ItemCategoryTabCategories from './item-category-tab-categories/ItemCategoryTabCategories.vue'

import useItemCategoryView from './useItemCategoryView'

export default {
  components: {
    ItemCategoryBioPanel,
    ItemCategoryTabItems,
    ItemCategoryTabOverview,
    ItemCategoryTabCategories,
  },
  setup() {
    const itemCategoryTab = ref(null)

    const { route } = useRouter()

    const {
      loading,
      itemCategory,

      init,
      destroy,
    } = useItemCategoryView(route.value.params.id)

    init()
    onUnmounted(() => {
      destroy()
    })

    // watch(
    //   () => route.value.params.id,
    //   id => init(id),
    //   { immediate: true },
    // )

    const tabs = [
      {
        icon: mdiAccountOutline,
        title: 'Overview',
        append: true,
        to: '#overview',
      },
      {
        icon: mdiClipboardListOutline,
        title: 'Inner Categories',
        append: true,
        to: '#categories',
      },
      {
        icon: mdiFormatListBulleted,
        title: 'Products',
        append: true,
        to: '#items',
      },
    ]

    return {
      tabs,
      itemCategoryTab,

      itemCategory,
      loading,
    }
  },
}
</script>

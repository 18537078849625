<template>
  <div class="user-tab-overview">
    <!-- activity timeline -->
    <v-card class="">
      <v-card-title>
        Details
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-list
          two-line
          multi-line
        >
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('ID') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.id }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Code') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.code }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Name') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.displayName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Parent Category') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                <router-link
                  v-if="data.parentId !== null"
                  :to="{ name : 'item-category-view', params : { id : data.parentId } }"
                  class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  <span>{{ data.parentCode }}</span>
                  <span v-if="data.parentCode && data.parentDisplayName"> - </span>
                  <span>{{ data.parentDisplayName }}</span>
                </router-link>
                <span
                  v-else
                  class="text--secondary"
                >-</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { useUtils } from '@/@core/libs/i18n'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useUtils()

    return {
      t,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
